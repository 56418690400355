import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import{ Configuration } from '../../config/configuration';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {

  public user_details;
  public logoUrl;
  public log_out_URL;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.user_details = this.commonService.valid_user_details;
    this.logoUrl = Configuration.logoClickUrl;
    this.log_out_URL = Configuration.logoutClickUrl;
  }

  logout_head(){
    this.commonService.ShowLoadingOverlay("Please Wait...");
    // window.open('https://www.protoexpress.com/pcb/', '_self');
    window.open(  this.log_out_URL  , '_self');
  }

}
